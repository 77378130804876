import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '@rocketseat/gatsby-theme-docs/src/components/Layout';
import SEO from '@rocketseat/gatsby-theme-docs/src/components/SEO';
import PostNav from '@rocketseat/gatsby-theme-docs/src/components/Docs/PostNav';
import EditGithub from '@rocketseat/gatsby-theme-docs/src/components/Docs/EditGithub';


function findSubpageGroup({ allSidebarItems }, slugToFind) {
  let subItems = null
  let parentNode = null
  allSidebarItems.edges.find(edge => {
    if (edge.node.items == null) {
      edge.node.items = []
    }

    return edge.node.items.find(item => {

      if (item.items != null) {
        const isSubitemFound = item.items.find(subItem => {
          return slugToFind.includes(subItem.link) || subItem.link.includes(slugToFind)
        })

        if (isSubitemFound) {
          subItems = item.items
          parentNode = item
          return true
        }
      }

      return false
    })
  })
  return { subItems, parentNode }
}

function getFlattenedSidebarList({ allSidebarItems }) {
  let fullList = []
  allSidebarItems.edges.forEach(edge => {
    fullList = [...fullList, ...edge.node.items]
  })
  return fullList
}


export default function Docs({ mdx, pageContext }) {
  const { prev, next, githubEditUrl } = pageContext;
  const { title, description, image, disableTableOfContents } = mdx.frontmatter;
  const { headings, body } = mdx;
  const { slug } = mdx.fields;

  const sidebarData = useStaticQuery(graphql`
  query{
    allSidebarItems {
      edges {
        node {
          id
          items {
            label
            link
            items {
              label
              link
            }
          }
        }
      }
    }
  }
    `);
  const { subItems: subPageList, parentNode } = findSubpageGroup(sidebarData, pageContext.slug)
  const mainItems = getFlattenedSidebarList(sidebarData)

  let newNext = next
  let newPrev = prev

  if (subPageList != null) {
    const index = subPageList.findIndex(elem => pageContext.slug.includes(elem.link))
    newNext = subPageList[index + 1] || next
    newPrev = subPageList[index - 1] || prev

    if (newNext.link === "/" && parentNode != null) {
      const mainItemIndex = mainItems.findIndex(elem => parentNode.link === elem.link)
      if (mainItems[mainItemIndex + 1] != null) {
        newNext = mainItems[mainItemIndex + 1]
      }
    }
  }

  return (
    <>
      <SEO title={title} description={description} slug={slug} image={image} />
      <Layout
        disableTableOfContents={disableTableOfContents}
        title={title}
        headings={headings}
      >
        <MDXRenderer>{body}</MDXRenderer>
        <EditGithub githubEditUrl={githubEditUrl} />
        <PostNav prev={newPrev} next={newNext} />
      </Layout>
    </>
  );
}


